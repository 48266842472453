/* Header */

#header {
    padding: 1rem;
    background-color: #ffffff;
    color: #FFFFFF;
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-template-areas: "header-left header-middle header-right";
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
    box-shadow: 0 4px 4px -2px rgba(0, 0, 0, 0.25);
}

.header-left {
    display: flex;
    align-items: center;
    grid-area: header-left;
    grid-column-start: 1;
    grid-column-end: 2;
    width: 250px;
    color: #FFFFFF;
}

.user-initials {
    font-size: 22px;
    color: var(--primary-colour-accent);
    height: auto;
    margin: auto;
}

.hide-text {
    color: var(--primary-colour-accent) !important;
}

.signup,
.signin {
    cursor: pointer;
}

.logo {
    color: var(--primary-colour-charcoal);
    text-shadow: 2px 2px 4px var(--primary-colour-accent);
    margin: auto;
}

.header-right-narrow-screen {
    display: none;
}

.header-right-wide-screen {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    grid-area: header-right;
    grid-column-start: 3;
    grid-column-end: 4;
    width: 250px;
    margin-right: 5px;
}

.icon {
    margin-right: 10px;
    height: 60px;
    width: 60px;
    border-radius: 50%;
    border: 2px solid var(--primary-colour-charcoal);
    box-sizing: content-box;
    background-color: var(--primary-colour-charcoal);
    padding: 2px;
}

.icon:hover,
.user-initials:hover {
    background-color: #d8dbde;
}

.icon-header,
.icon-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid var(--primary-colour-charcoal);
    background-color: var(--primary-colour-charcoal);
}

.icon-header img,
.icon-footer img {
    height: 28px;
}

.footer-narrow-screen {
    display: none;
}

.user-initials {
    margin: auto !important;
}

.icon-header:hover {
    background-color: #d8dbde;
}

.signup {
    border: 2px solid #FFFFFF;
    padding: 12px;
    border-radius: 30%;
}

.signup:hover {
    background-color: #d8dbde;
    color: #0070c0;
}

.signin {
    border: 2px solid #FFFFFF;
    padding: 12px;
    border-radius: 30%;
}

.signin:hover {
    background-color: #d8dbde;
    color: #0070c0;
}

.signup,
.signin {
    cursor: pointer;
}

.header-middle {
    padding: 5px;
    font-size: 50px;
    grid-area: header-middle;
    grid-column-start: 2;
    grid-column-end: 3;
    justify-self: center;
    height: auto;
}

.header-right {
    align-items: center;
    justify-content: flex-end;
    grid-area: header-right;
    grid-column-start: 3;
    grid-column-end: 4;
    flex-grow: 1;
    width: 250px;
}

/* Main */

#main {
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.main--layout {
    display: grid;
    grid-template-columns: auto 1fr auto;
    place-content: center;
    background-color: #FFFFFF;
    justify-content: center;
    align-items: center;
    max-width: 100vw;
}

.shop--css {
    height: 80px;
    text-align: center;
    width: 100%;
    padding: 8px 0;
    background-color: var(--primary-colour-charcoal);
    border-radius: 20px 20px 0 0;
    color: var(--primary-colour-white);
}

.description--css {
    color: var(--primary-colour-charcoal);
    text-align: center;
    margin: 0;
    font-size: 32px;
    padding: 8px;
    margin-top: 20px;
    height: 40px;
    overflow: auto;
}

.input-container {
    display: grid;
    grid-template-columns: 1fr 14fr 1fr;
    width: 100%;
}

.input-container-accent {
    color: var(--primary-colour-accent);
    font-size: 18px;
    width: 120px;
    margin: auto;
    margin-top: 0;
    margin-bottom: 12px;
}

.input-container-narrow-screen {
    display: none;
}

#submit-item-button,
#back-item-button, 
#submit-final-item-button  {
    height: 60px;
    width: 240px;
    font-size: 30px;
    border-radius: 20px;
    cursor: pointer;
    border: none;
}

#submit-item-button,
#back-item-button {
    background-color: var(--primary-colour-charcoal);
    color: var(--primary-colour-white);
}

#submit-final-item-button {
    background-color: var(--primary-colour-accent);
    color: var(--primary-colour-white);
}

.sub-total,
.item-count,
.item-count-accent {
    font-weight: 600 !important;
    font-size: 16px;
    margin: 8px 0;
}

.sub-total,
.item-count {
    color: var(--primary-colour-charcoal);
}

.item-count-accent {
    color: var(--primary-colour-accent);
}

.value-input {
    font-size: 24px;
    width: 180px;
    height: 40px;
    text-align: center;
}

.box {
    margin-top: 20px;
    margin-bottom: 20px;
    grid-column: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 1350px;
    border-radius: 20px;
    box-shadow: 0 0 5px var(--primary-colour-charcoal);
}

.clear-button {
    background-color: inherit;
    border: none;
}

.product-image {
    display: block;
    margin: 0 auto;
    height: 400px;
}

.box h2 {
    margin: 0;
    font-size: 32px;
    padding: 8px 0;
}

.info-container p {
    margin: 4px 20px;
    font-size: 18px;
}

.accent {
    color: var(--primary-colour-accent);
}

.box img {
    max-height: 350px;
}

.info-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.num-pad {
    display: grid;
    grid-template-areas:
        '7 8 9 enter'
        '4 5 6 enter'
        '1 2 3 enter'
        '. 0 ⌫ back';
    grid-template-columns: repeat(4, 80px);
    grid-template-rows: repeat(4, 40px);
}

#enter {
    grid-column: 4;
    grid-row: 1 / span 3;
    margin-top: 2px;
    height: 115px;
    width: 80px;
}

#back {
    grid-column: 4;
    grid-row: 4;
    height: 35px;
    margin-top: 2px;
    font-size: 18px;
    vertical-align: middle;
    text-align: center;
}

.narrow-screen-buttons {
    display: grid;
    grid-template-rows: 3fr 1fr;
    gap: 4px
}

.narrow-back-item-button,
.narrow-submit-final-item-button,
.narrow-submit-item-button {
    width: 100%;
    border-radius: 5px;
    font-size: 16px;
    background-color: var(--primary-colour-white);
    color: var(--primary-colour-charcoal);
    border: 2px solid var(--primary-colour-charcoal);
}

.narrow-submit-final-item-button,
.narrow-submit-item-button {
    display: grid;
    grid-template-columns: auto 1fr auto;
}

.narrow-submit-item-button {
    color: var(--primary-colour-accent);
}

.narrow-submit-final-item-button {
    background-color: var(--primary-colour-accent) !important;
    color: var(--primary-colour-white) !important;
    border: 2px solid var(--primary-colour-accent) !important;
}

.num-pad-button {
    margin: 2px;
    height: 35px;
    width: 70px;
    background-color: var(--primary-colour-white);
    color: var(--primary-colour-charcoal);
    border: 2px solid var(--primary-colour-charcoal);
    border-radius: 3px;
    font-size: 18px;
}

.delete-button,
.decimal-button {
    width: 60px;
    background-color: var(--primary-colour-white) !important;
    color: var(--primary-colour-charcoal) !important;
}

.info-column.store {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: calc(50% - 55px);
}

.info-column {
    width: 50%;
    margin: 10px;
}

.date {
    text-align: left;
}

.store {
    text-align: center;
}

.game {
    text-align: right;
}

.enter-icon {
    margin: auto;
    height: 50px;
    width: 20px;
}

.timer {
    font-size: 2rem;
    color: #fff;
    text-align: center;
    padding: 0.5rem;
    background-color: #f18213;
    border-radius: 50%;
    width: 3rem;
    height: 3rem;
    margin: 0 auto;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1rem;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Results Section */

.box-results {
    margin: 10px;
    display: grid;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.percentile-results,
.statistics-results,
.share-results {
    padding: 4px;
}

.h1-results,
.percentile-results,
.statistics-results {
    max-width: 400px;
    margin: 0 auto;
}

.main--layout--results {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.h1-results {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--primary-colour-charcoal);
    font-size: 1rem;
}

.h1-results h1,
.result-buttons {
    margin: 8px 0;
}

.result-buttons {
    display: grid;
    grid-template-columns: auto auto auto;
}

.percentile-results p {
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
}

.percentile-results {
    display: flex;
    justify-content: center;
    align-items: center;
}

.percentile-results>p {
    height: 96px;
    width: 150px;
    border-color: var(--primary-colour-charcoal);
    font-weight: bold;
    justify-content: center;
    font-size: 1.6rem;
    text-align: center;
    margin: 0;
    border: 2px solid var(--primary-colour-charcoal);
}

.percentile-results>.percentile-box {
    background-color: var(--primary-colour-charcoal);
    color: var(--primary-colour-white);
    font-size: 1.6rem;
    height: 100px;
    font-weight: bold;
    width: 200px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.statistics-results {
    display: flex;
    justify-content: center;
    align-items: center;
}

.bold {
    font-weight: bold;
}

.normal-font {
    font-weight: normal;
}

.main--layout h1 {
    font-size: 55px;
    margin: 0;
    height: auto;
}

.share-button-styling {
    background-color: #0070c0;
}

.share-button-styling:hover {
    background-color: #262829;
}

.share-button-styling,
.feedback-button-share {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    height: 55px;
    width: 200px;
    border-radius: 8%;
    font-size: 1.5rem;
    margin: 0 auto;
    text-align: center;
    text-decoration: none;
    margin-bottom: 5px;
}

.share-results {
    display: flex;
    justify-content: center;
    align-items: center;
}

.dropdown-menu {
    position: absolute;
    background-color: var(--primary-colour-charcoal);
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 1;
    right: 0;
    top: 64px;
}

.dropdown-menu .dropdown-item {
    color: var(--primary-colour-accent);
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    width: 100%;
    cursor: pointer;
    border-bottom: 1px solid var(--primary-colour-accent);
}

.dropdown-menu .dropdown-item:last-child {
    border-bottom: none;
}

.dropdown-menu .dropdown-item:hover {
    background-color: #f1f1f1;
}

/*MEDIA QUERIES*/
@media screen and (max-width: 1600px) {
    .box,
    .info-container {
        width: 1100px;
    }
}

@media screen and (max-width: 1100px) {
    .logo {
        font-size: 36px !important;
    }

    .coming-soon-text {
        margin-top: 16px !important;
    }

    .box img {
        max-height: 250px;
    }

    .result-buttons {
        display: grid;
        grid-template-columns: auto;
    }

    .play-button-styling, .signin-button-styling, .login-button-styling, .return-button-styling, .statistics-button-styling, .breakdown-button-styling, .feedback-button-styling {
        margin-top: 0;
    }

    .user-initials {
        font-size: 20px;
    }

    .box,
    .info-container {
        width: 850px;
    }

    #submit-item-button, 
    #back-item-button, 
    #submit-final-item-button {
        height: 50px;
        width: 120px;
        font-size: 22px;
        border-radius: 15px;
    }

    .value-input {
        font-size: 20px;
        width: 120px;
    }
}

@media screen and (max-width: 850px) {
    .header-right-wide-screen,
    .footer-wide-screen {
        display: none;
    }

    .icon-header img,
    .icon-footer img {
        height: 22px;
    }

    .footer-narrow-screen {
        display: grid;
    }

    .header-right-narrow-screen {
        display: grid;
        grid-template-columns: auto auto;
        grid-area: header-right;
        grid-column-start: 3;
        grid-column-end: 4;
        flex-grow: 1;
        width: 120px;
    }

    .header-left-wide-screen {
        width: 120px;
    }

    .icon {
        height: 60px !important;
    }

    .box,
    .info-container {
        width: 650px;
    }

    .box img {
        max-height: 200px;
    }

    #header,
    .footer--layout {
        height: 64px;
    }

    .footer--layout {
        padding: 0;
    }
}

@media screen and (max-width: 650px) {
    .logo {
        font-size: 24px !important;
    }

    .shop--css {
        font-size: 30px;
        height: auto;
    }

    .description--css {
        margin-top: 0px;
        height: 64px;
        margin-bottom: 4px;
    }

    #header {
        padding: 0 1rem;
    }

    .info-column {
        margin: 4px;
    }

    .input-container-wide-screen {
        display: none;
    }    

    .input-container-narrow-screen {
        display: grid;
        width: 100%;
        font-size: 14px;
    }

    .info-container p {
        font-size: 14px;
    }

    .num-pad-container {
        display: grid;
        grid-template-columns: 1fr auto 1fr;
        margin-bottom: 8px;
    }

    .input-container-narrow-screen-accent {
        color: var(--primary-colour-accent);
        margin: 8px 0 4px 0;
    }

    .user-initials {
        font-size: 20px;
    }

    .main--layout h1 {
        font-size: 40px;
        margin: 0;
    }

    .icon {
        height: 40px !important;
    }

    .box,
    .info-container {
        width: 530px;
    }

    #submit-item-button, 
    #back-item-button, 
    #submit-final-item-button {
        height: 40px;
        width: 80px;
        font-size: 18px;
        border-radius: 12px;
    }

    .value-input {
        font-size: 16px;
        width: 100px;
    }
}

@media screen and (max-width: 530px) {
    .logo {
        font-size: 16px !important;
    }

    .user-initials {
        font-size: 18px;
    }

    .header-right-narrow-screen,
    .header-left,
    .header-right {
        width: 100px !important;
    }

    #login-header {
        max-width: 280px !important;
    }

    #login-header h2 {
        font-size: 20px !important;
    }

    .landing-page {
        width: 300px;
    }

    .logo-landing {
        font-size: 32px !important;
    }

    .percentile-results>.percentile-box {
        font-size: 0.9rem;
        height: 60px;
        width: 120px;
    }

    .percentile-results>p {
        font-size: 2rem;
        height: 56px;
        width: 120px
    }

    .feedback-button-styling,
    .share-button-styling,
    .statistics-button-styling,
    .breakdown-button-styling {
        height: 50px;
        width: 180px;
        font-size: 1.5rem;
    }

    .play-button-styling, 
    .signin-button-styling, 
    .login-button-styling, 
    .return-button-styling {
        width: 250px;
    }

    .main--layout--results h1 {
        font-size: 1.5rem;
    }

    .box,
    .info-container {
        width: 375px;
    }

    .box img {
        max-height: 150px;
    }

    .footer--layout {
        padding: 0;
    }

    .icon-footer,
    .icon-header {
        width: 35px;
        height: 35px;
    }

    #submit-item-button, 
    #back-item-button, 
    #submit-final-item-button {
        height: 30px;
        width: 60px;
        font-size: 12px;
        border-radius: 10px;
    }

    .value-input {
        font-size: 12px;
        width: 80px;
    }
}

@media screen and (max-width: 400px) {
    .main--layout h1 {
        font-size: 30px;
    }

    .icon {
        height: 30px !important;
    }

    .box,
    .info-container {
        width: 350px;
    }

    .logo {
        font-size: 14px !important;
    }
}
