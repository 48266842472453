.main--layout--share {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 83vh;
}

.box-share {
    border: 2px solid #0070c0;
    height: 740px;
    width: 550px;
    align-items: center;
    text-align: center;
}

.h1-share {
    color: #0070c0;
    text-align: left !important;
    margin-left: 20px;
    font-size: 3rem;
    margin-bottom: 10px;
}

.share-image {
    display: block;
    margin: 0 auto;
}

.buttons-share-container {
    display: grid;
    grid-template-rows: repeat(7, 1fr);
}

.email-button {
    height: 90px;
    width: 300px;
}

.instabutton {
    height: 90px;
    width: 300px;
}

.facebook-button {
    height: 60px;
    width: 195px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.whatsapp-button {
    height: 60px;
    width: 195px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.twitter-button {
    height: 60px;
    width: 195px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.reddit-button {
    height: 60px;
    width: 195px;
    margin-top: 8px;
    margin-bottom: 8px;
}

.feedback-button-share {
    background-color: #262829;
}

.feedback-button-share:hover {
    background-color: #0070c0;
}