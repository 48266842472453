.modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    background-color: var(--primary-colour-charcoal);
    max-width: 80vw;
    min-width: 70vw;
    max-height: 90vh;
    border-radius: 24px;
    border: 2px solid var(--primary-colour-accent);
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.modal::-webkit-scrollbar {
    display: none;
}

.badge-container {
    flex: 1;
    text-align: center;
    justify-content: center;
    align-items: center;
}

.badge {
    max-width: 100px;
    height: auto;
    display: block;
}

.badge-description {
    margin-top: 5px;
}

.link-email {
    color: var(--primary-colour-accent);
    margin-left: 4px;
}

.list-reset {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
    list-style: none;
}

.close-btn {
    background-color: inherit;
    margin-top: 18px;
    margin-right: 18px;
    font-size: 1rem;
    font-weight: bolder;
    color: var(--primary-colour-accent);
    position: absolute;
    top: 0;
    right: 0;
}

.table-row-breakdown {
    border-bottom: 2px dotted var(--primary-colour-accent);
    padding: 8px 0 8px 4px;
}

.modal h1 {
    font-size: 2rem;
    color: var(--primary-colour-accent);
}

.instructions-h1 {
    font-size: 2rem;
}

.modal h2 {
    font-size: 1.2rem;
    padding-bottom: 4px;
    color: var(--primary-colour-accent);
}

.modal ul {
    list-style-type: disc;
}

.modal h1,
.modal h2,
.modal p {
    text-align: center;
}

.chartContainer {
    width: 100%;
    height: 100%;
    position: relative;
}

.myChart {
    width: 100%;
    height: 100%;
    background-color: #d8d8d8;
}

.modal-stats {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--primary-colour-accent);
    background-color: var(--primary-colour-charcoal);
    max-height: 90vh;
    border-radius: 24px;
    border: 2px solid var(--primary-colour-accent);
    max-width: 55vw;
    min-width: 50vw;
    padding: 20px;
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.modal-display {
    color: var(--primary-colour-accent);
}

.dotted-border {
    border-bottom: 2px dotted var(--primary-colour-accent);
}

@media (max-width: 785px) {
        .bg-number {
            font-size: 18px !important;
            margin-top: 5px !important;
        }
    
        .ag-number {
            font-size: 18px !important;
            margin-top: 5px !important;
        }
        .gp-number {
            font-size: 18px !important;
        }
        .cs-number {
            font-size: 18px !important;
        }
        .bs-number {
            font-size: 18px !important;
        }
}

@media (max-width: 600px) {
    .modal-stats {
        min-width: 300px;
    }
}

@media (max-height: 730px) {
    .bg-number {
        font-size: 18px !important;
        margin-top: 5px !important;
    }

    .ag-number {
        font-size: 18px !important;
        margin-top: 5px !important;
    }

    .gp-number {
        font-size: 18px !important;
    }

    .cs-number {
        font-size: 18px !important;
    }

    .bs-number {
        font-size: 18px !important;
    }
    .h1-statistics {
        font-size: 30px !important;
        margin-top: 5px !important;
        margin-bottom: 5px !important;
    }
    .myChart {
        height: 350px !important;
        width: 330px !important;
        padding-right: 50px !important;
    }
}

.modal-stats::-webkit-scrollbar {
    display: none;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    height: 75px;
    margin-bottom: 30px;
}

.h1-statistics {
    font-weight: bold;
    font-size: 45px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.grid-item {
    padding: 10px;
    font-weight: bold;
}

.gp-number,
.cs-number,
.bs-number {
    font-size: 30px;
}

.current-streak,
.best-streak,
.games-played,
.best-guess,
.average-guess {
    font-size: 13px;
    margin-top: 10px;
}

.grid-container2 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    height: 120px;
}

.grid-item2 {
    padding: 15px;
    font-weight: bold;
}

.pl-l {
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bg-number,
.ag-number {
    font-size: 30px;
    margin-top: 5px;
}

.grid-container3 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
}

.grid-item3 {
    font-weight: bold;
}

.ranking {
    margin-top: 10px;
    font-size: 13px;
    margin-bottom: 10px;
}

.players {
    margin-top: 10px;
    font-size: 13px;
    margin-bottom: 20px;
}

.rank-number {
    font-size: 30px;
    color: #0070c0;
}

.players-number {
    font-size: 30px;
    color: #0070c0;
}

.twitter-share-btn {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.twitter-share-btn img {
    width: 40px;
    height: 40px;
}
