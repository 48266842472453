.landing-page {
  place-items: center;
  margin: auto;
  width: 400px;
  padding: 30px;
}

.form {
  display: grid;
  background-color: #fff;
  border-radius: 10px;
  justify-items: center;
}

.landing-page h1 {
  font-size: 3rem;
  color: var(--primary-colour-charcoal);
  margin-top: 8px;
  height: 80%;
}

.form label {
  margin-bottom: 20px;
}

.form input[type="email"],
.form input[type="password"],
.form input[type="text"],
.form select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 100%;
  box-sizing: border-box;
  height: 40px;
  color: #666;
}

.form button[type="submit"]:hover {
  background-color: #005693;
}

.switch-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.switch-text {
  color: var(--primary-colour-charcoal);
  cursor: pointer;
  transition: color 0.2s;
}

.switch-text:hover {
  color: black;
}

.logo-landing {
  font-weight: 1000;
  font-size: 50px !important;
  color: var(--primary-colour-charcoal);
}

.trolley-icon {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: var(--primary-colour-charcoal);
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
}

.error {
  max-width: 330px;
  text-align: center;
}

@media screen and (max-width: 800px) {
  .landing-page {
    padding: 10px;
  }
}

@media screen and (max-width: 530px) {
  #landing-page-menu button, 
  .form button {
    padding: 4px;
    margin-top: 0px !important;
  }
}