:root {
  --primary-colour-charcoal: #36454F;
  --primary-colour-accent: #40E0D0;
  --primary-colour-off-white: #cbced0;
  --primary-colour-white: #fff;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body::-webkit-scrollbar {
  display: none;
}

.center-wrapper {
  margin: 0 auto;
}

.list-reset {
  list-style: none;
}

.app {
  min-height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-rows: auto 1fr auto;
}

button,
.cursor {
  cursor: pointer;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* CSS for the Footer layout */

.footer--layout {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  align-items: center;
  background-color: var(--primary-colour-charcoal);
  padding: 0 8px;
  height: 70px;
}

.left-icons {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
}

.middle-content {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

.icon-mail img {
  margin-top: 4px;
  width: 20px;
}

.contact {
  font-size: 18px;
  color: var(--primary-colour-white);
  text-decoration: none;
}

.right-content {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-end;
}

.footer-row {
  cursor: pointer;
  font-size: 14px;
  color: #ffffff;
}

.timer-to-midnight,
.coming-soon-text {
  font-weight: bold;
  color: var(--primary-colour-accent);
  margin-top: 8px;
}

.timer-to-midnight {
  font-size: 2.2rem;
  margin-bottom: 6px;
}

.coming-soon-text {
  font-size: 1.8rem;
  margin-top: 24px !important;
  margin-bottom: 10px;
}


.play-button-styling,
.login-button-styling,
.statistics-button-styling,
.breakdown-button-styling {
  background-color: var(--primary-colour-charcoal);
  color: var(--primary-colour-white) !important;
}

.play-button-styling:hover,
.login-button-styling:hover,
.statistics-button-styling:hover,
.breakdown-button-styling:hover {
  background-color: var(--primary-colour-white) !important;
  color: var(--primary-colour-charcoal) !important;
}

.play-button-styling,
.signin-button-styling,
.login-button-styling,
.return-button-styling,
.statistics-button-styling,
.breakdown-button-styling,
.feedback-button-styling {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
  height: 55px;
  width: 330px;
  border-radius: 12px;
  font-size: 1.5rem;
  margin-top: 12px;
  text-align: center;
  text-decoration: none;
  border: 2px solid var(--primary-colour-charcoal);
}

.signin-button-styling,
.return-button-styling,
.feedback-button-styling {
  background-color: var(--primary-colour-white) !important;
  color: var(--primary-colour-charcoal) !important;
}

.signin-button-styling:hover,
.return-button-styling:hover,
.feedback-button-styling:hover {
  background-color: var(--primary-colour-charcoal) !important;
  color: var(--primary-colour-white) !important;
}

.footer-row {
  padding: 5px 0;
  color: #f7f7f7;
}

.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: auto;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}

.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--primary-colour-charcoal);
}

.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}

.lds-roller div:nth-child(1):after {
  top: 63px;
  left: 63px;
}

.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}

.lds-roller div:nth-child(2):after {
  top: 68px;
  left: 56px;
}

.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}

.lds-roller div:nth-child(3):after {
  top: 71px;
  left: 48px;
}

.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}

.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}

.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}

.lds-roller div:nth-child(5):after {
  top: 71px;
  left: 32px;
}

.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}

.lds-roller div:nth-child(6):after {
  top: 68px;
  left: 24px;
}

.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}

.lds-roller div:nth-child(7):after {
  top: 63px;
  left: 17px;
}

.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}

.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12px;
}

@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*GRIDS*/
.two-columns-expand-one {
  display: grid;
  grid-template-columns: 1fr auto;
}

.two-columns-expand-two {
  display: grid;
  grid-template-columns: auto 1fr;
}

.auto-columns {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.two-rows {
  display: grid;
  grid-template-rows: auto auto;
}

.four-columns-expand-one {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
}

.three-columns-expand-one-three {
  display: grid;
  grid-template-columns: 1fr auto 1fr;
}

.vertical-align {
  vertical-align: middle;
}

.ten-columns {
  display: grid;
  grid-template-columns: repeat(10, auto);
}

.three-columns-auto {
  display: grid;
  grid-template-columns: repeat(3, auto);
}

.three-columns-fr {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.three-rows-expand-two {
  display: grid;
  grid-template-rows: auto 1fr auto;
}

.five-columns-expand-two-four {
  display: grid;
  grid-template-columns: auto 1fr auto 1fr auto;
}

.five-columns-expand-one-five {
  display: grid;
  grid-template-columns: 1fr auto auto auto 1fr;
}

.three-rows-expand-one-three {
  display: grid;
  grid-template-rows: 1fr auto 1fr;
}

.five-rows-expand-three {
  display: grid;
  grid-template-rows: auto auto 1fr auto auto;
}

.eight-rows-expand-six {
  display: grid;
  grid-template-rows: auto auto auto auto auto 1fr auto auto;
}

/*PADDING AND MARGINS*/
.pl-none {
  padding-left: 0;
}

.pt-xs {
  padding-top: 2px;
}

.pl-m {
  padding-left: 16px;
}

.pl-l {
  padding-left: 24px;
}

.pr-l {
  padding-right: 24px;
}

.pd-m {
  padding: 16px;
}

.pt-s {
  padding-top: 8px;
}

.mt-m {
  margin-top: 16px;
}

.mb-m {
  margin-bottom: 16px;
}

.ml-xs {
  margin-left: 4px;
}

.mb-xxl {
  margin-bottom: 46px;
}

.mr-s {
  margin-right: 8px;
}

.ml-s {
  margin-left: 8px;
}

.mt-s {
  margin-top: 8px;
}

.mt-xl {
  margin-top: 40px;
}

.mg-m {
  margin: 16px;
}